import { Headline, Body } from '@sumup/circuit-ui';
import { ChevronRight } from '@sumup-oss/icons';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import {
  SectionContainer,
  SectionCard,
  SectionCardContent,
  StyledIcon,
  Hyperlink,
  SectionCardNavigation,
  ViewArticle,
} from '../styledComponents';

const SECTION_ICON_SIZE = '112';
const PosProTypedSection = () => {
  const { t } = useTranslation();
  const PosArticles = [
    {
      articleId: '1',
      title: 'How do I access my billing details?',
      href: 'https://support.thegoodtill.com/how-do-i-access-my-billing-details',
    },
    {
      articleId: '2',
      title: 'Goodeats table ordering',
      href: 'https://support.thegoodtill.com/support/goodeats-table-ordering',
    },
    {
      articleId: '3',
      title: 'POS App Syncing Guidelines',
      href: 'https://support.thegoodtill.com/syncing-guidelines',
    },
  ];
  return (
    <SectionContainer key={'section.id'}>
      <SectionCard spacing={'giga'}>
        <SectionCardContent>
          <StyledIcon
            src={`https://images.ctfassets.net/214q1nptnllb/1B1OXHJ8drjRp8LgHZ5L9t/108a283d5dc2cccd10527f79546137c4/2206_HelpCenter_POS_L_Icon_V01.png?w=${SECTION_ICON_SIZE}&h=${SECTION_ICON_SIZE}`}
            alt={'iconName'}
            width={SECTION_ICON_SIZE}
            height={SECTION_ICON_SIZE}
          />

          <Headline size="four" as="h3">
            {'POS PRO - Goodtill'}
          </Headline>
          {PosArticles.map((article) => (
            <Body key={article.articleId}>
              <Link passHref href={article.href}>
                <Hyperlink size="one">{article.title}</Hyperlink>
              </Link>
            </Body>
          ))}
        </SectionCardContent>
        <SectionCardNavigation>
          <Body>
            <Link passHref href={'https://support.thegoodtill.com/'}>
              <ViewArticle variant="highlight">
                {t('view_all_articles')}
                <ChevronRight aria-hidden="true" />
              </ViewArticle>
            </Link>
          </Body>
        </SectionCardNavigation>
      </SectionCard>
    </SectionContainer>
  );
};

export default PosProTypedSection;
