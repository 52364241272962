import React, { FC } from 'react';
import {
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Linkedin,
} from '@sumup-oss/icons';

const nameIconMap = {
  twitter: Twitter,
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  youtube: Youtube,
};

type Props = {
  name: string;
};

const SocialIcon: FC<Props> = ({
  name,
  ...rest
}: {
  name: 'twitter' | 'facebook' | 'instagram' | 'linkedin' | 'youtube';
}) => {
  const Icon = nameIconMap[name];
  if (!Icon) {
    return null;
  }
  return <Icon {...rest} />;
};

export default SocialIcon;
