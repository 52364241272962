import React, { FC, forwardRef, Fragment, useMemo } from 'react';
import { get } from 'lodash/fp';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as icons from '@sumup-oss/icons';

import {
  HeaderCountryPickerOption,
  HeaderCountryPickerOptionContent,
  TabContent,
} from 'components/Header/components/styled';
import { ContentfulCountry } from 'types/common';
import { makeSCUrlFromLocale } from 'services/localization';
import { SELECTED_COUNTRY } from 'constants/countriesDetails';
import { isNewSupportCenterEnabled } from 'src/utils/featureFlag';

type CountryOptionsProps = {
  countriesList: Array<ContentfulCountry>;
  handleCountryClick: (el: ContentfulCountry) => void;
  currentCountry: string;
  enabledLocales: string[];
  removeModal?: () => void;
};

const CountryOptions: FC<CountryOptionsProps> = ({
  countriesList,
  currentCountry,
  enabledLocales,
  handleCountryClick,
  removeModal,
}) => {
  const router = useRouter();

  const BaseComponent = useMemo(
    () =>
      // eslint-disable-next-line prefer-arrow-callback
      forwardRef(function CustomComponent(
        {
          el,
          Flag,
          href,
          onClick,
        }: {
          el: ContentfulCountry;
          Flag: FC;
          href?: string;
          onClick?: () => void;
        },
        /* eslint-disable @typescript-eslint/no-unused-vars */
        _ref,
      ) {
        return (
          <HeaderCountryPickerOption
            key={el.name}
            className={
              el.twoLetterIsoCode === currentCountry ? SELECTED_COUNTRY : null
            }
            onClick={onClick}
            href={href}
          >
            <HeaderCountryPickerOptionContent>
              <Flag />
              {el.name}
            </HeaderCountryPickerOptionContent>
          </HeaderCountryPickerOption>
        );
      }),
    [currentCountry],
  );

  return (
    <TabContent>
      {countriesList.map((el) => {
        const Flag = (el.flagName ? icons[el.flagName] : Fragment) as FC;
        const scURL = makeSCUrlFromLocale({
          language: get(
            'supportedLanguages[0].fields.twoLetterIsoCode',
            el,
          ) as string,
          country: get('twoLetterIsoCode', el),
          oldSCURL: get('defaultZendeskSupportCentre', el),
          enabledLocales,
        });
        if (!(scURL.url || scURL.locale)) {
          return null;
        }
        const isNewMCC = isNewSupportCenterEnabled(scURL.locale);
        const linkConfig: {
          href: string;
          locale: false | string;
        } = scURL.locale
          ? {
              href: isNewMCC ? router.asPath : '/',
              locale: scURL.locale,
            }
          : {
              href: el.defaultZendeskSupportCentre,
              locale: false,
            };

        return get('supportedLanguages', el) &&
          el.supportedLanguages.length < 2 ? (
          <Link key={el.name} {...linkConfig} passHref>
            <BaseComponent
              onClick={removeModal}
              key={el.name}
              el={el}
              Flag={Flag}
            />
          </Link>
        ) : (
          <BaseComponent
            key={el.name}
            el={el}
            Flag={Flag}
            onClick={() => handleCountryClick(el)}
          />
        );
      })}
    </TabContent>
  );
};

export default CountryOptions;
