import React, { Ref } from 'react';
import Link from 'next/link';
import { Body } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { Article } from '@sumup-oss/icons';

import {
  ResultContainer,
  ResultSectionsContainer,
  ResultTitle,
} from './styled';
import styles from './result.module.css';
import ResultSection from './ResultSection';

import { ResultData } from 'types/search';
import { isNewSupportCenterEnabled } from 'src/utils/featureFlag';

interface ComponentProps {
  resultData: ResultData;
  withXPadding?: boolean;
  noLinks?: boolean;
  scrollRef?: Ref<Element>;
  inPage?: boolean;
  searchEvent?: () => void;
}

const Result = ({
  resultData,
  withXPadding = false,
  noLinks = false,
  inPage = false,
  scrollRef,
  searchEvent,
}: ComponentProps): JSX.Element => {
  const router = useRouter();

  return (
    <div
      className={
        isNewSupportCenterEnabled(router.locale) ? styles.container : ''
      }
    >
      <ResultContainer
        inPage={inPage}
        // @ts-expect-error Card component does not suppor the ref prop
        ref={scrollRef}
        withXPadding={withXPadding}
        nsc={isNewSupportCenterEnabled(router.locale)}
      >
        {noLinks ? (
          <ResultTitle
            noLinks={noLinks}
            size="one"
            nsc={isNewSupportCenterEnabled(router.locale)}
          >
            {resultData.title}
          </ResultTitle>
        ) : (
          <>
            <>{isNewSupportCenterEnabled(router.locale) && <Article />}</>
            <Link passHref href={`/articles/${resultData.id}`}>
              <ResultTitle
                onClick={() => searchEvent && searchEvent()}
                noLinks={noLinks}
                size="one"
                nsc={isNewSupportCenterEnabled(router.locale)}
              >
                {resultData.title}
              </ResultTitle>
            </Link>
          </>
        )}

        {!isNewSupportCenterEnabled(router.locale) && (
          <ResultSectionsContainer>
            {resultData?.sections.map((sectionData) => (
              <ResultSection
                key={sectionData.section_id}
                noLinks={noLinks}
                sectionData={sectionData}
              />
            ))}
          </ResultSectionsContainer>
        )}
        {isNewSupportCenterEnabled(router.locale) && (
          <div
            className={
              isNewSupportCenterEnabled(router.locale) ? styles.articleBody : ''
            }
          >
            <Body>{resultData.description}</Body>
          </div>
        )}
      </ResultContainer>
    </div>
  );
};

export default Result;
