import React from 'react';
import { Body } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { Article as ArticleIcon } from '@sumup-oss/icons';

import styles from './article.module.css';

import { ResultData } from 'src/types/search';

interface ArticleProps {
  article: ResultData;
  onClick: () => void;
  isMobile: boolean;
}

const Article: React.FC<ArticleProps> = ({ article, onClick, isMobile }) => {
  const router = useRouter();

  return (
    <div className={styles.container}>
      <Body
        role="button"
        onClick={() => {
          if (isMobile) {
            return;
          }
          router
            .push(`/articles/${article.id}`)
            .then(() => {
              onClick();
            })
            .catch(console.error);
        }}
        className={styles.articleBody}
      >
        <ArticleIcon />
        <div
          className={styles.title}
          onTouchEnd={() => {
            if (isMobile) {
              router
                .push(`/articles/${article.id}`)
                .then(() => {
                  onClick();
                })
                .catch(console.error);
            }
          }}
        >
          {article.title}
        </div>
        <Body className={styles.body}>{article.description}</Body>
      </Body>
    </div>
  );
};

export default Article;
